// import { Record } from 'immutable';

import { SubscriptionInterval } from '../../../enums'
import ACTIONS from './constants'

export interface LayoutStateType {
  loading: boolean
  dialogs: {
    dialog?: string
    show?: boolean
  }
}

// const LayoutState = Record<LayoutStateType>({
//   loading: false,
//   dialogs: {},
// });

// const initialState = new LayoutState();

const initialState = {
  loading: false,
  dialogs: {},
  subscriptionInterval: SubscriptionInterval.Monthly,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_LAYOUT_STATE: {
      // return state.merge(state, {
      //   ...action.data,
      // });
      return { ...state, ...action.data }
    }

    case ACTIONS.TOGGLE_DIALOG: {
      const { dialog, show } = action.data
      // return state.mergeIn(['dialogs'], state.dialogs, {
      //   [dialog]: show,
      // });
      return { ...state, dialogs: { ...state.dialogs, [dialog]: show } }
    }

    case ACTIONS.SET_SUBSCRIPTION_INTERVAL: {
      const { subscriptionInterval } = action.data
      return { ...state, subscriptionInterval }
    }

    default:
      return state
  }
}
