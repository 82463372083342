module.exports = [
  {
    name: 'Sankaku Web App',
    client_id: 'sankaku-web-app',
    token_endpoint_auth_method: 'none',
    reject_unverified: false,
    grant_types: ['authorization_code', 'refresh_token'],
    response_types: ['code'],
    redirect_uris: [
      'https://stg.sankaku-web.dev.politetech.com/sso/callback',
      'https://stg.plus.sankaku-web.dev.politetech.com/sso/callback',
      'https://stg.beta.sankaku-web.dev.politetech.com/sso/callback',
      'https://stg.black.sankaku-web.dev.politetech.com/sso/callback',
      'https://stg.white.sankaku-web.dev.politetech.com/sso/callback',
      'https://455b-14-160-25-244.ngrok-free.app/sso/callback'
    ],
  },
  // {
  //   name: 'Sankaku Plus Web App',
  //   client_id: 'sankaku-web-premium-cli',
  //   token_endpoint_auth_method: 'none',
  //   grant_types: ['authorization_code', 'refresh_token'],
  //   response_types: ['code'],
  //   reject_unverified: false,
  //   redirect_uris: [
  //     'https://sankaku-web-plus.lastingdynamics.com/sso/callback',
  //   ],
  // },
  {
    name: 'Sankaku Payments',
    client_id: 'sankaku-payments',
    token_endpoint_auth_method: 'none',
    reject_unverified: false,
    grant_types: ['authorization_code', 'refresh_token'],
    response_types: ['code'],
    redirect_uris: [
      'https://stg.sankaku-payment.dev.politetech.com/sso/callback',
    ],
  },
  {
     name: 'Sankaku News',
     client_id: 'sankaku-wp',
     token_endpoint_auth_method: 'none',
     reject_unverified: true,
     grant_types: ['authorization_code', 'refresh_token'],
     response_types: ['code'],
     redirect_uris: [
       'https://sank.entropi.me/wp-admin/admin-ajax.php?action=openid-connect-authorize',
       'https://sankakucomplex.dev-test.pro/wp-admin/admin-ajax.php?action=openid-connect-authorize',
       'https://www.sankakucomplex.com/wp-admin/admin-ajax.php?action=openid-connect-authorize',
       'http://sankaku/wp-admin/admin-ajax.php?action=openid-connect-authorize',
     ],
  },
  {
    name: 'Sankaku Android App',
    client_id: 'sankaku-android-app',
    application_type: 'native',
    token_endpoint_auth_method: 'none',
    reject_unverified: false,
    grant_types: ['authorization_code', 'refresh_token'],
    response_types: ['code'],
    redirect_uris: [
      'app.sankaku.black://sso/callback',
      'app.sankaku.white://sso/callback',
      'com.complexapp://sso/callback',
    ],
  },
  // {
  //   name: 'Sankaku React Native App',
  //   client_id: 'sankaku-react-native-app',
  //   application_type: 'native',
  //   token_endpoint_auth_method: 'none',
  //   reject_unverified: false,
  //   grant_types: ['authorization_code', 'refresh_token'],
  //   response_types: ['code'],
  //   redirect_uris: ['com.sankakumobile://sso/callback', 'com.sankakumobileblack://sso/callback', 'com.sankakumobilewhite://sso/callback'],
  //   payments_integration_enabled: true,
  // },
  // {
  //   name: 'Sankaku iOS App',
  //   client_id: 'sankaku-ios-app',
  //   application_type: 'native',
  //   token_endpoint_auth_method: 'none',
  //   reject_unverified: false,
  //   payments_integration_enabled: false,
  //   grant_types: ['authorization_code', 'refresh_token'],
  //   response_types: ['code'],
  //   redirect_uris: [
  //     'app.sankaku.ios://sso/callback',
  //   ],
  // },
  // {
  //   name: 'Sankaku Matrix',
  //   // Must correspond to client_id in homeserver.yaml
  //   client_id: 'matrix',
  //   grant_types: ['authorization_code', 'refresh_token'],
  //   token_endpoint_auth_method: 'none',
  //   response_types: ['code'],
  //   redirect_uris: ['https://matrix.sankakucomplex.com/_synapse/client/oidc/callback'],
  //   reject_unverified: true,
  //   payments_integration_enabled: false,
  // },
  // {
  //   name: 'Sankaku Test',
  //   client_id: 'sankaku-test-app',
  //   token_endpoint_auth_method: 'none',
  //   reject_unverified: false,
  //   grant_types: ['authorization_code', 'refresh_token'],
  //   response_types: ['code'],
  //   redirect_uris: ['https://test.app/sso/callback'],
  // },
];
