import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import i18next from 'i18next'
import i18nextXHRBackend from 'i18next-xhr-backend'
import { I18nextProvider } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'

import { getUserLocation } from '../utils/api/auth'
import { setLocale } from '../store/app/actions'
import { DEFAULT_COUNTRY, DEFAULT_LANG, languages } from '../utils/i18n'

import en from './locales/en.json'
import fr from './locales/fr.json'
import ja from './locales/ja.json'

if (__BROWSER__) {
  i18next.use(i18nextXHRBackend)
}

// i18next.use(__BROWSER__ ? i18nextXHRBackend : {}).init({
i18next.init({
  backend: {
    // for all available options read the backend's repository readme file
    loadPath: '/locales/{{lng}}.json',
  },
  react: {
    // Must be false until Suspense is supported on the server side
    useSuspense: false,
    wait: true,
  },
  debug: process.env.NODE_ENV === 'development' && __BROWSER__,
  fallbackLng: 'en',
  fallbackNS: ['translation'],
  // This option is necessary to tell i18next to try loading missing resources via
  // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
  // partialBundledLanguages: true,
  resources: {
    en: { translation: en },
    fr: { translation: fr },
    ja: { translation: ja },
  },
  parseMissingKeyHandler: (missing) => {
    if (process.env.NODE_ENV === 'development' && __BROWSER__) {
      console.warn('MISSING TRANSLATION:', missing)
    }
    return missing
  },
})

i18next.languages = ['en', 'fr', 'ja']

interface Language {
  locale: string
  short: string
  countryCode: string
  name: string
}

const I18N = ({ children }: { children: object }) => {
  const { query } = useLocation()
  const dispatch = useDispatch()
  const [cookies, setCookies] = useCookies()

  const getLocation = async () => {
    let detectedLanguage
    try {
      if (cookies.lang) {
        detectedLanguage = cookies.lang
      } else {
        const { data } = await getUserLocation()
        const countryCode = data.success ? data.detectedCountry : DEFAULT_COUNTRY
        const foundCountry = (Object.values(languages) as any).find(
          (lang: Language) => lang.countryCode === countryCode,
        )
        detectedLanguage = (foundCountry && foundCountry.short) || DEFAULT_LANG
        setCookies('lang', detectedLanguage, { path: '/' })
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      dispatch(setLocale(detectedLanguage))
      i18next.changeLanguage(detectedLanguage)
    }
  }

  useEffect(() => {
    if (query && query.lang) {
      i18next.changeLanguage(query.lang)
      setCookies('lang', query.lang, { path: '/' })
    } else {
      getLocation()
    }
  }, [query])

  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
}

export default React.memo(I18N)
