import { SERVER_HOST } from '../configs'

export interface GeneratedFormParams {
  nonce?: any
  token?: string
  state?: string
}

export const generateHtmlForm = (
  { nonce, token, state }: GeneratedFormParams,
  submitUrl: string,
) => {
  const f = document.createElement('form')
  f.setAttribute('method', 'post')
  f.setAttribute('style', 'opacity: 0')
  f.setAttribute('action', `${SERVER_HOST}${submitUrl}`)

  const t = document.createElement('input')
  t.setAttribute('type', 'text')
  t.setAttribute('name', 'access_token')
  t.setAttribute('value', token as string)
  f.appendChild(t)

  if (state) {
    const q = document.createElement('input')
    q.setAttribute('type', 'text')
    q.setAttribute('name', 'state')
    q.setAttribute('value', state)
    f.appendChild(q)
  }

  if (nonce) {
    const s = document.createElement('input')
    s.setAttribute('type', 'text')
    s.setAttribute('name', 'nonce')
    s.setAttribute('value', nonce)
    f.appendChild(s)
  }

  document.getElementsByTagName('body')[0].appendChild(f)
  f.submit()
}
