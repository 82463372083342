// import { Record } from 'immutable';
import ACTIONS from './constants'

// const NotificationState = Record({
//   btn: undefined,
//   btnTitle: undefined,
//   duration: 400000000,
//   message: '',
//   messageType: '',
//   type: '',
//   visible: false,
// });

// const initialState = new NotificationState();

const initialState = {
  btn: undefined,
  btnTitle: undefined,
  duration: 400000000,
  message: '',
  messageType: '',
  type: '',
  visible: false,
}

export default function notifyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.OPEN_NOTIFICATION: {
      // return new NotificationState({
      //   ...action.data,
      //   visible: !state.get('visible'),
      // });
      return { ...state, ...action.data, visible: !state.visible }
    }

    case ACTIONS.CLOSE_NOTIFICATION: {
      // return state.merge(state, action.data);
      return { ...state, ...action.data }
    }

    case ACTIONS.RESET_NOTIFICATION: {
      // return new NotificationState();
      return { ...initialState }
    }

    default:
      return state
  }
}
