const ReactPiwik = require('react-piwik')

const {
  REACT_APP_MATOMO_ENABLED,
  REACT_APP_MATOMO_JS_NAME,
  REACT_APP_MATOMO_PHP_NAME,
  REACT_APP_MATOMO_SITE_ID,
  REACT_APP_MATOMO_URL,
} = process.env

const matomoEnabled = JSON.parse(REACT_APP_MATOMO_ENABLED || 'false')

let piwik
let mtm

const isStarted = () => !!piwik

const Analytics = {
  start() {
    if (!matomoEnabled) {
      return
    }

    if (!REACT_APP_MATOMO_URL || !REACT_APP_MATOMO_SITE_ID) {
      throw new Error('Analytics are not configured properly')
    }

    if (isStarted()) {
      console.warn('Analytics already started')
      return
    }

    piwik = new ReactPiwik({
      url: REACT_APP_MATOMO_URL,
      siteId: Number(REACT_APP_MATOMO_SITE_ID),
      trackErrors: true,
      jsFilename: REACT_APP_MATOMO_JS_NAME || 'piwik.js',
      phpFilename: REACT_APP_MATOMO_PHP_NAME || 'piwik.php',
    })

    Analytics.mtmInit()
  },

  /**
   * Since ReactPiwik doesn't support Matomo Tag Manager
   * We handle it based on https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/1515978753?src=-1626393933
   * It must be declared after Piwik has initialized/started
   */
  mtmInit() {
    if (!mtm) {
      // eslint-disable-next-line no-multi-assign
      mtm = (window as any)._mtm = (window as any)._mtm || []
    }
  },

  mtmPush(event) {
    if (mtm) {
      mtm.push(event)
    }
  },

  push(...items) {
    if (!matomoEnabled) return

    ReactPiwik.push(...items)
  },

  track(location) {
    if (!matomoEnabled || !piwik) return

    piwik.track(location)
  },
}

export default Analytics
