// import { Map } from 'immutable';
import ACTIONS from './constants'

// export const initialState = Map({
//   currentUser: {},
//   loading: false,
//   loginError: undefined,
// });

export const initialState = {
  currentUser: {},
  loading: false,
  loginError: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN_START: {
      // return state.set('loading', false);
      return { ...state, loading: false }
    }
    case ACTIONS.LOGIN_SUCCESS: {
      // return state.withMutations((m) => {
      //   m.set('currentUser', { ...action.data }).set('loading', false);
      // });
      return { ...state, currentUser: { ...action.data }, loading: false }
    }
    case ACTIONS.LOGIN_ERROR: {
      // return state.withMutations((m) => m.set('loading', false).set('loginError', action.error));
      return { ...state, loading: false, loginError: action.error }
    }

    // Get me info
    case ACTIONS.GET_ME_START: {
      // return state.set('loading', true);
      return { ...state, loading: true }
    }
    case ACTIONS.GET_ME_SUCCESS: {
      // return state.set('currentUser', { ...action.payload }).set('loading', false);
      return { ...state, currentUser: { ...action.payload }, loading: false }
    }
    case ACTIONS.GET_ME_ERROR: {
      // return state.set('currentUser', { ...action.payload }).set('loading', false);
      return { ...state, currentUser: { ...action.payload }, loading: false }
    }
    default: {
      return state
    }
  }
}
