import { useEffect } from 'react'

const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script')
    const s = document.getElementsByTagName('script')[0]

    script.src = url
    script.async = true

    if (s && s.parentNode) {
      s.parentNode.insertBefore(script, s)
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export default useScript
