export default {
  GET_ME_START: 'users/GET_ME_START',
  GET_ME_SUCCESS: 'users/GET_ME_SUCCESS',
  GET_ME_ERROR: 'users/GET_ME_ERROR',

  LOGIN_START: 'auth/LOGIN_START',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_ERROR: 'auth/LOGIN_ERROR',

  REGISTRATION_START: 'auth/REGISTRATION_START',
  REGISTRATION_SUCCESS: 'auth/REGISTRATION_SUCCESS',
  REGISTRATION_ERROR: 'auth/REGISTRATION_ERROR',

  REGISTER_PASSKEY_START: 'auth/REGISTER_PASSKEY_START',
  REGISTER_PASSKEY_SUCCESS: 'auth/REGISTER_PASSKEY_SUCCESS',
  REGISTER_PASSKEY_ERROR: 'auth/REGISTER_PASSKEY_ERROR',
  UPDATE_USER: 'auth/UPDATE_USER',

  LOGIN_PASSKEY_START: 'auth/LOGIN_PASSKEY_START',
  LOGIN_PASSKEY_SUCCESS: 'auth/LOGIN_PASSKEY_SUCCESS',
  LOGIN_PASSKEY_ERROR: 'auth/LOGIN_PASSKEY_ERROR',
}
