import ACTIONS from './constants'

export interface PasskeyStateType {
  visible: boolean
  actionType: string
  isOpened: boolean
  isError: boolean
}

const initialState = {
  visible: false,
  actionType: '',
  isOpened: false,
  isError: false,
  data: {},
}

export default function passkeyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.OPEN_PASSKEY_DIALOG: {
      return { ...state, ...action.data, visible: true }
    }

    case ACTIONS.CLOSE_PASSKEY_DIALOG: {
      return { ...state, ...action.data, visible: false }
    }

    case ACTIONS.SET_PASSKEY_DIALOG_STATE: {
      return { ...state, ...action.data, data: { ...state.data, ...action.data.data } }
    }

    default:
      return state
  }
}
