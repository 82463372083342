import loadable from '@loadable/component'
// import Loading from '../../components/Loading';

const Plans = loadable(() => import('./Plans'))
const Privacy = loadable(() => import('./Privacy'))
const TermsOfService = loadable(() => import('./TermsOfService'))
const VerifiedEmail = loadable(() => import('./VerifiedEmail'))
const AppDownloads = loadable(() => import('./AppDownloads'))
const CompanionApp = loadable(() => import('./CompanionApp'))
// const Login = loadable(() => import('./login'));
// const Registration = loadable(() => import('./registration'));

export default {
  Plans,
  Privacy,
  TermsOfService,
  VerifiedEmail,
  AppDownloads,
  CompanionApp,
}
