export default {
  OPEN_OTP_DIALOG: 'OPEN_OTP_DIALOG',
  CLOSE_OTP_DIALOG: 'CLOSE_OTP_DIALOG',
  SET_OTP_DIALOG_STATE: 'SET_OTP_DIALOG_STATE',
}

export const actionsInMfa = {
  login: {
    name: 'login',
    verify: true,
  },
}
