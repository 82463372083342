import React, { useEffect } from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { loadableReady } from '@loadable/component'

import { configureStore } from '../shared/store'
import AppRouter from '../shared/Router'
import IntlProvider from '../shared/i18n/IntlProvider'
import createHistory from '../shared/store/history'
import NetworkService, { POST_MESSAGE_TYPE } from '../shared/utils/helpers/network-service'
import Analytics from '../shared/utils/analytics'
import useScript from '../shared/hooks/useScript'

const history = createHistory()

// Create/use the store
// history MUST be passed here if you want syncing between server on initial route
const store = window.store
  ? window.store
  : configureStore({
      initialState: window.__PRELOADED_STATE__,
    })

NetworkService.setupInterceptors(store)

Analytics.start()
if (process.env.REACT_APP_MATOMO_DOMAINS) {
  Analytics.push(['setDomains', process.env.REACT_APP_MATOMO_DOMAINS])
  Analytics.push(['enableCrossDomainLinking'])
}
Analytics.push(['enableHeartBeatTimer'])
Analytics.mtmPush({
  'mtm.startTime': new Date().getTime(),
  event: 'mtm.Start',
})

const Main = () => {
  // prettier/prettier
  // const [ theme ] = useTheme();
  // const { theme } = useTheme();

  // Load script container Tag Manager Matomo
  useScript(process.env.REACT_APP_MATOMO_CONTAINER_URL)

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    const notifyParent = () => {
      window.parent.postMessage(
        { type: POST_MESSAGE_TYPE.IFRAME_URL_CHANGE, url: window.location.href },
        '*',
      )
    }

    notifyParent() // Notify parent on initial load
    window.addEventListener('hashchange', notifyParent)
    window.addEventListener('popstate', notifyParent)

    return () => {
      window.removeEventListener('hashchange', notifyParent)
      window.removeEventListener('popstate', notifyParent)
    }
  }, [])

  return (
    <Provider store={store}>
      <Router history={history}>
        <IntlProvider>
          <HelmetProvider>
            <link
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap"
              rel="stylesheet"
            />
            <AppRouter />
          </HelmetProvider>
        </IntlProvider>
      </Router>
    </Provider>
  )
}

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, { collapseGroups: true })
}

loadableReady(() => hydrate(<Main />, document.getElementById('app')))

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept()
  }

  if (!window.store) {
    window.store = store
  }
}
