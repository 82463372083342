import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Cookie from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import { getMe, refreshToken as callToRefreshToken, verifyRequest } from '../store/auth/actions'
import { isTokenExpired } from '../utils/helpers/network-service'
import ACTIONS from '../store/auth/constants'
import { generateHtmlForm } from '../utils/helpers/generateForm'
import clients from '../../../environment/sso-clients'
import useEntryQuery from './useEntryQuery'
import { SANKAKU_APP_CLIENT_ID, SANKAKU_PAYMENT_CLIENT_ID } from '../utils/configs'
import { openNotification } from '../store/notification/actions'

const cookies = new Cookie()

export const getAuthStatus = () => {
  const accessToken = cookies.get('access_token')
  const refreshToken = cookies.get('refresh_token')

  const isAccessTokenValid = accessToken && !isTokenExpired(accessToken)
  const isRefreshTokenValid = refreshToken && !isTokenExpired(refreshToken)

  return {
    refreshToken,
    accessToken,
    isAccessTokenValid,
    isRefreshTokenValid,
  }
}

export const isAuthProcessRedirection = (query, pathname, parseQuery): boolean => {
  const clientId = parseQuery?.client_id

  return (
    pathname === '/login' &&
    query.submit_url &&
    clientId &&
    (<{ client_id: string }[]>clients).find((c) => c.client_id === clientId)
  )
}

/**
 * If user access WEB or PAYMENT and send first authentication check request to SSO,
 * then redirect back if user not logged in at /sso/callback route
 * @param {string} route SSO destination route
 * @param {string} clientId SSO client
 * @returns {boolean} is first time checking authentication of WEB and Payment client
 */
export const isClientFirstCheck = (route?: string, clientId?: string): Boolean =>
  [SANKAKU_APP_CLIENT_ID, SANKAKU_PAYMENT_CLIENT_ID].includes(`${clientId}`) && !route

const useAuth = () => {
  const { query, pathname } = useLocation()
  const dispatch: AsyncDispatch = useDispatch()
  const { parseQuery, returnUri } = useEntryQuery()
  const { t } = useTranslation()

  /**
   * Check is authentication request from another platform such as a web app or payment
   * Used in case the client (WebApp or Payment) loss their credentials
   * and redirect to SSO to check.
   */
  const handleSsoProcess = () =>
    dispatch(getMe()).then((user) => {
      if (!user) return

      if (
        query.reject_unverified &&
        JSON.parse(query.reject_unverified) &&
        user.email_verification_status === 'unverified'
      ) {
        dispatch({ type: ACTIONS.GET_ME_ERROR, error: { user } })
        dispatch(
          openNotification({
            message: t('snackbar__verify_email'),
            type: 'error',
            btn: () => dispatch(verifyRequest(query.entry_query, user.email)),
            btnTitle: t('common-title__verify_resend'),
          }),
        )
      } else if (query.submit_url) {
        generateHtmlForm(
          {
            token: cookies.get('access_token'),
            nonce: query.nonce,
          },
          query.submit_url,
        )
      }
    })

  const authProcess = async () => {
    const { refreshToken, isAccessTokenValid, isRefreshTokenValid } = getAuthStatus()
    const isLoggedIn = isAccessTokenValid || isRefreshTokenValid

    // Try to refresh access_token in case of refresh_token valid and access_token is invalid
    if (!isAccessTokenValid && isRefreshTokenValid) {
      await dispatch(callToRefreshToken({ refresh_token: refreshToken }))
      return handleSsoProcess()
    }

    /**
     * If there is an authentication request from another platform such as a web app or payment
     * then go to function if is Logged in before go to handleSsoProcess.
     */
    if (
      isClientFirstCheck(parseQuery.route, parseQuery?.client_id) &&
      isAuthProcessRedirection(query, pathname, parseQuery) &&
      !isLoggedIn
    ) {
      const redirectUrl = parseQuery.redirect_uri || returnUri?.origin
      const redirectQuery = new URLSearchParams({
        returnUrl: `${returnUri?.pathname}${returnUri?.search}`,
      })
      /**
       * If return_uri origin is different from the client that is authenticating,
       * then save the return_uri in query to handle after logged in
       */
      if (
        returnUri &&
        parseQuery.redirect_uri &&
        new URL(parseQuery.redirect_uri).origin !== returnUri.origin
      ) {
        redirectQuery.set('state', `return_uri=${encodeURIComponent(returnUri.href)}`)
      }
      window.location.href = `${redirectUrl}?${redirectQuery.toString()}`
    } else if (isLoggedIn) {
      handleSsoProcess()
    }
  }

  useEffect(() => {
    authProcess()
  }, [])

  return { ...getAuthStatus() }
}

export default useAuth
