import { AuthenticationResponseJSON } from '@simplewebauthn/server/script/deps'

export enum MultiFactorAuthMethod {
  NONE,
  MAIL,
  AUTHENTICATOR,
}

export enum AuthFactor {
  TOTP = 'totp',
  AUTHENTICATOR = 'authenticator',
}

export enum InvalidTimesMFA {
  FIRST = 5,
  SECOND = 10,
}

export enum PLATFORM {
  WHITE_APP = 'white-app',
  BLACK_APP = 'black-app',
  WEB_APP = 'web-app',
  iOS_NATIVE = 'ios-native',
  ANDROID_NATIVE = 'android-native',
  SSO = 'sso',
}

export interface MFAParams {
  login: string
  code?: string
  passkeysAttestation?: AuthenticationResponseJSON
  challenge?: string
}

export enum SubscriptionInterval {
  Weekly = 'weekly',
  Monthly = 'monthly',
  SixMonths = '6-month',
}

export enum SubscriptionLevel {
  Free,
  Plus = 10,
  Infinite = 30,
}

export enum SubscriptionPointAmount {
  PlusWeekly = 20,
  InfiniteWeekly = 200,
  Plus = 100,
  Infinite = 1000,
}

export const priceConfig = {
  [SubscriptionLevel.Plus]: {
    [SubscriptionInterval.Weekly]: 599,
    [SubscriptionInterval.Monthly]: 1199,
    [SubscriptionInterval.SixMonths]: 4999,
  },
  [SubscriptionLevel.Infinite]: {
    [SubscriptionInterval.Weekly]: 1199,
    [SubscriptionInterval.Monthly]: 2999,
    [SubscriptionInterval.SixMonths]: 14999,
  },
}
