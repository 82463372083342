import orange from '@material-ui/core/colors/orange'
import grey from '@material-ui/core/colors/grey'
import { createTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    comparison: {
      [themeName: string]: string
    }
    colors?: {
      [themeName: string]: string
    }
    cardBackgroundFree?: string
    cardBackgroundPlus?: string
    cardBackgroundInfinite?: string
    txtCodeInputColor?: string
    iframe: { [themeName: string]: string }
  }
  interface PaletteOptions {
    comparison: {
      [themeName: string]: string
    }
    textColor?: string
    textDialog?: string
    backdropColor?: string
    subTextColor?: {
      [themeName: string]: string
    }
    colors?: {
      [themeName: string]: string
    }
    cardBackgroundFree?: string
    cardBackgroundPlus?: string
    cardBackgroundInfinite?: string
    txtCodeInputColor?: string
    iframe: { [themeName: string]: string }
  }
  interface TypeBackground {
    drawer: string
    body: string
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface ThemeOptions {
    snackbar?: {
      [themeName: string]: string
    }
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface TypographyOptions {
    FontColor?: string
  }
  interface Typography {
    htmlFontSize?: string
  }
}

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

const colors = {
  purple10: '#A159FE',
  white: '#FFFFFF',
  yellow14: '#F3D014',
  gray10: '#373737',
  gray11: '#F1F1F1',
  gray20: '#3E3E3E',
  gray27: '#454545',
  gray56: '#8F8F8F',
  gray87: '#DEDEDE',
  gray95: '#F2F2F2',
  gray51: '#828282',
  orange10: '#FB8C00',
}

export default {
  white: createTheme({
    palette: {
      background: {
        default: '#fff',
        body: '#eceff1',
      },
      primary: {
        ...orange,
        main: orange[600],
        contrastText: '#fff',
      },
      secondary: {
        light: '#fff',
        main: '#fff',
        dark: '#fff',
      },
      comparison: {
        light: '#000',
        main: '#000',
        dark: grey[400],
      },
      // secondary: {
      //   light: '#FF8C00',
      //   main: '#FF8C00',
      //   dark: '#FF8C00',
      // },
      textDialog: 'rgba(0, 0, 0, 0.54)',
      backdropColor: 'rgba(0, 0, 0, 0.1)',
      subTextColor: {
        main: 'rgb(0, 0, 0, 0.54)',
      },
      colors,
      cardBackgroundFree: colors.gray87,
      cardBackgroundPlus: colors.gray11,
      cardBackgroundInfinite: colors.gray95,
      txtCodeInputColor: '#101010',
      iframe: {
        body: '#fff',
      },
    },
    typography: {
      // useNextVariants: true,
      // FontColor: 'rgba(0, 0, 0, 0.87)'
    },
    overrides: {
      MuiChip: {
        root: {
          color: '#fff',
          marginTop: defaultTheme.spacing(),
          marginBottom: defaultTheme.spacing(),
        },
        deleteIcon: {
          color: 'rgba(255, 255, 255, 0.7)',
          '&:hover, &:focus': {
            color: '#fff',
          },
        },
        icon: {
          color: '#fff',
        },
        label: {
          color: '#fff',
          display: 'inline',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          flex: '1 1 auto',
        },
      },
      MuiDivider: {
        root: {
          height: 2,
          backgroundColor: orange[600],
        },
      },
      MuiTypography: {
        h3: {
          [breakpoints.down('xs')]: {
            fontSize: '1.25rem',
          },
        },
      },
    },
  }),
  black: createTheme({
    palette: {
      type: 'dark',
      background: {
        default: '#424242',
        body: '#303030',
      },
      primary: {
        ...orange,
        main: orange[600],
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(0, 0, 0, 0.87)',
        main: 'rgba(0, 0, 0, 0.87)',
        dark: 'rgba(0, 0, 0, 0.87)',
      },
      // secondary: {
      //   light: '#FF8C00',
      //   main: '#FF8C00',
      //   dark: '#FF8C00',
      // },
      textColor: 'rgba(255, 255, 255, 0.87)',
      comparison: {
        light: '#000',
        main: '#FFF',
        dark: grey[500],
      },
      textDialog: 'rgba(255, 255, 255, 0.7)',
      backdropColor: 'rgba(0, 0, 0, 0.2)',
      subTextColor: {
        main: 'rgb(255, 255, 255, 0.54)',
      },
      colors,
      cardBackgroundFree: colors.gray10,
      cardBackgroundPlus: colors.gray20,
      cardBackgroundInfinite: colors.gray27,
      txtCodeInputColor: colors.white,
      iframe: {
        body: '#424242',
      },
    },
    typography: {
      // useNextVariants: true,
      FontColor: 'rgba(0, 0, 0, 0.87)',
    },
    snackbar: {
      backgroundColor: 'rgb(105, 105, 105)',
      textColor: 'rgba(255, 255, 255, 0.87)',
      actionColor: orange[600],
    },
    overrides: {
      MuiChip: {
        root: {
          color: '#fff',
          marginTop: defaultTheme.spacing(),
          marginBottom: defaultTheme.spacing(),
        },
        deleteIcon: {
          color: 'rgba(255, 255, 255, 0.7)',
          '&:hover, &:focus': {
            color: '#fff',
          },
        },
        icon: {
          color: '#fff',
        },
        label: {
          color: '#fff',
          display: 'inline',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          flex: '1 1 auto',
        },
      },
      MuiDivider: {
        root: {
          height: 2,
          backgroundColor: orange[600],
        },
      },
      MuiTypography: {
        h3: {
          [breakpoints.down('xs')]: {
            fontSize: '1.25rem',
          },
        },
      },
    },
  }),
}
