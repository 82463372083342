import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useCookies } from 'react-cookie'
import base64url from 'base64url'

// Utils
const useTheme = (isSetCookies = true) => {
  const [cookies, setCookies] = useCookies(['theme'])
  const { query, pathname } = useLocation()

  useEffect(() => {
    if (
      query.theme &&
      (pathname === '/privacy-policy' || pathname === '/terms-of-service' || pathname === '/')
    ) {
      window.history.replaceState('', '', pathname)
    }
  }, [query])

  const theme = useMemo(() => {
    const entryQuery =
      (query && query.entry_query && new URLSearchParams(base64url.decode(query.entry_query))) ||
      false
    const setTheme =
      (entryQuery && entryQuery.get('theme')) || (query && query.theme) || cookies.theme || 'white'
    if (isSetCookies) setCookies('theme', setTheme, { path: '/' })
    return setTheme
  }, [query])

  return { theme }
}

export default useTheme
